<template>
  <div>
    <h1 class="mt-4 mb-4">Usuarios</h1>

    <div class="row">
      <div class="col-sm-auto mb-1">
        <button type="button" @click="showNuevo = !showNuevo" class="btn m-1 btn-outline-primary"><i class="fas fa-plus"></i> Nuevo</button>
      </div>

      <div class="col-sm-auto">
        <Multiselect
          v-model="selectedOption"
          :options="options"
          @search-change="searchUsuario"
          placeholder="Busca un usuario"
          :internal-search="false"
          :custom-label="userWithFullName"
          @input="$router.push({ name: 'Usuario', params: { embyId: selectedOption.embyId } })"
        >
        </Multiselect>
      </div>

      <div class="col-sm-auto">
        <LoadingButton @clicked="copiarCorreos" :iconProp="'fas fa-envelope'" :loadingProp="loadingCopiarCorreos" :classProp="'btn btn-outline-primary'" :textProp="'Copiar correos'"></LoadingButton>
      </div>
    </div>

    <CrearUsuario v-show="showNuevo" @nuevo-creado="showNuevo = false"></CrearUsuario>

    <Router-view></Router-view>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import axios from 'axios'
  import config from '../config'
  import CrearUsuario from '../components/usuarios/CrearUsuario.vue'
  import LoadingButton from '../components/LoadingButton.vue'
  import Swal from 'sweetalert2'

  export default {
    data() {
      return {
        options: [],
        selectedOption: null,
        showNuevo: false,
        loadingCopiarCorreos: false
      }
    },
    components: {
      Multiselect,
      CrearUsuario,
      LoadingButton
    },
    methods: {
      async searchUsuario(query) {
        try {
          if (!query) {
            return
          }

          const res = await axios.get(config.apiUrl + '/usuarios/buscar?query=' + query)

          this.options = res.data
        } catch (e) {
          console.log(e)
        }
      },
      userWithFullName(option) {
        return `${option.usuario} - ${option.nombreCompleto}`
      },
      async copiarCorreos() {
        try {
          this.loadingCopiarCorreos = true

          const res = await axios.get(config.apiUrl + '/usuarios/correos')

          const correos = res.data.join(',')

          this.$clipboard(correos)

          Swal.fire({
            title: 'Bien',
            text: 'Correos copiados en el portapapeles',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.loadingCopiarCorreos = false
        } catch (e) {
          this.loadingCopiarCorreos = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    },
    mounted() {
      this.$store.commit('setPanelLayout')
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
