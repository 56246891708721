<template>
  <div class="card bg-light shadow mt-3">
    <div class="card-body">
      <p>Usuario</p>
      <input type="text" v-model="nuevoUsuario.usuario" class="form-control">
      <p>Nombre</p>
      <input type="text" v-model="nuevoUsuario.nombre" class="form-control">
      <p>Apellidos</p>
      <input type="text" v-model="nuevoUsuario.apellidos" class="form-control">
      <p>Contraseña</p>
      <input type="password" v-model="nuevoUsuario.passwd" class="form-control">
      <p>Correo</p>
      <input type="email" v-model="nuevoUsuario.correo" class="form-control">
      <p>Admin</p>
      <input type="checkbox" v-model="nuevoUsuario.admin" class="form-control">

      <div class="text-center m-2">
        <LoadingButton @clicked="crearUsuario" :iconProp="'fas fa-paper-plane'" :loadingProp="loadingNuevo" :classProp="'btn btn-outline-info'" :textProp="'Crear'" class="m-1"></LoadingButton>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../../config'
  import LoadingButton from '../LoadingButton.vue'
  import Swal from 'sweetalert2'

  export default {
    data() {
      return {
        loadingNuevo: false,
        nuevoUsuario: {
          usuario: null,
          nombre: null,
          apellidos: null,
          correo: null,
          passwd: null,
          admin: false
        }
      }
    },
    components: {
      LoadingButton
    },
    methods: {
      async crearUsuario() {
        try {
          this.loadingNuevo = true
          const res = await axios.post(config.apiUrl + '/usuarios/nuevo', this.nuevoUsuario)
          this.loadingNuevo = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.$emit('nuevo-creado')
        } catch (e) {
          this.loadingNuevo = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    }
  }
</script>
